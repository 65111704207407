
.mat-table thead th,
.text-wrap table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

tr.mat-header-row {
    height: 0px !important;
}

table {
    width: 100%;
}

th.mat-header-cell {
    vertical-align: middle;
    text-align: center !important;
}

th.mat-header-cell:first-of-type {
    padding-left: 0 !important;
}

td.mat-cell {
    padding: none !important;
    border-bottom-style: 1px !important;
}

.mat-table>td {
    text-align: center;
}

.mat-icon {
    cursor: pointer
}

mat-label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

html {
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.collapse:not(.show) {
    display: none;
    background: none !important;
}

.nav-link {
    color: #ffffff !important;
}

.nav-item.active .nav-link {
    font-weight: bold;
}

.off {
    background: none;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 1px 22px 0px 22px;
    height: 35px;
}

.off:hover {
    background-color: #16c79a;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #16c79a;
    border-radius: 20px;
    padding: 1px 22px 0px 22px;
    height: 35px;
}

.right .off:hover {
    background: #eee;
    color: #03A9F4;
    box-shadow: none;
}

.left .off:hover {
    box-shadow: none;
    color: #03A9F4;
    background: #363A3D;
}

.btn-begin {
    padding: 5px 35px 5px 35px;
    border-radius: 20px;
    background-color: #16c79a;
    color: #ffffff;
    font-size: 16px;
}

.btn-begin:hover {
    padding: 5px 35px 5px 35px;
    border-radius: 20px;
    background-color: #16c79a;
    color: #ffffff;
    font-size: 16px;
}

.data-counter {
    background-color: #19456b;
    border-radius: 20px;
    color: #c7c7c7;
    font-weight: bold;
}

.data-counter .mt-2:hover {
    background-color: #383e56;
    border-radius: 20px;
    color: #ffffff;
    font-weight: bold;
}

.data-counter a {
    color: #c7c7c7;
}

.request-demo-btn {
    border-radius: 20px;
    padding: 7px 35px 7px 35px;
    border: solid 2px #ffffff;
    background-color: rgba(255, 255, 255, 0.13);
    color: #ffffff;
}

.request-demo-btn::hover {
    border-radius: 20px;
    padding: 7px 35px 7px 35px;
    border: solid 2px #ffffff;
    background-color: rgba(255, 255, 255, 0.13);
    color: #ffffff;
}

.service h2 {
    font-size: 35px;
    font-weight: bold;
    color: #b9b9b9;
}

.service .row {
    border: solid 0.1px #1643a333;
}

.service .row:hover {
    box-shadow: 10px 20px 30px 0 rgb(0 0 0 / 18%) !important;
}

.service strong {
    font-size: 20px;
}

.service h1 {
    font-size: 35px;
    font-weight: bold;
}

.form-demo {
    border-radius: 50px;
    border: solid 0.5px #aec3b9;
}

.form-demo:hover {
    box-shadow: 10px 20px 30px 0 rgb(0 0 0 / 18%) !important;
}

.form-demo h1 {
    font-size: 35px;
    font-weight: bold;
}

.btn-submit {
    padding: 10px 100px 10px 100px;
    background-color: #ee6f57;
    color: #ffffff;
    border-radius: 6px;
}

.form-control:focus {
    box-shadow: 1px 5px 20px 0 rgb(56 62 86 / 40%) !important;
    border: solid 1px #383e56 !important;
    background-color: #ffffff !important;
    outline: 0 !important;
}

.searchformfld {
    position: relative;
    margin: 5px 0px;
}

.searchformfld label {
    position: absolute;
    padding-left: 10px;
    top: 15px;
    cursor: text;
}

.searchformfld input:focus+label,
.searchformfld input:not(:placeholder-shown)+label {
    opacity: 1;
    transform: scale(.9) translateY(-100%) translateX(-10px);
    color: #000;
}

.searchformfld input:focus {
    border: 1px solid #000;
    outline-color: #000;
}

.searchformfld {
    padding: 15px;
    margin: 15px 0px;
}

.searchformfld input {
    width: 100%;
    padding-left: 10px;
}

.searchformfld label,
.searchformfld input {
    transition: all 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
}

.footer {
    box-shadow: 0 3px 15px 0 #b4b4b4;
    background-color: #19456b;
    height: 140px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .form-demo h1 {
        font-size: 25px;
        font-weight: bold;
    }
}

.success-snackbar {
    background-color: #16c79a;
}

.spinner-btn:before {
    content: '';
    box-sizing: border-box;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: 1%;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner-btn .8s linear infinite;
}

.cdk-overlay-container {
    z-index: 1031 !important;
}
html {
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
    background-color: #eff7fe !important;
    /* font-family: 'Muli' !important; */
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.header {
    box-shadow: 0 3px 15px 0 #b4b4b4 !important;
    background-color: #11698e !important;
}

.collapse:not(.show) {
    display: none;
    background: none !important;
}

.nav-link {
    color: rgba(255, 255, 255, 0.88) !important;
}

.nav-item.active .nav-link {
    font-weight: bold;
}

.nav-item.active i {
    color: #16c79a;
    background: #ffffff;
    border-radius: 4px;
}


/* .nav-item i {
    background-color: #69b8d96b;
    padding: 4px;
    border-radius: 4px;
    color: #fdf6ff;

}

.nav-item:hover i {
    background-color: #9fb8ad;
    padding: 4px;
} */

nav .nav-item {
    margin: 5px;
    line-height: 1.2;
}

.fe {
    font-size: 20px;
}

.header-search {
    width: 190px;
    font-size: 11px;
    background-color: #fdf6ff00 !important;
    border: 1px solid rgb(0 40 100 / 0%) !important;
    border-bottom: solid 1px #9695957a !important;
}

.header-search:focus {
    background-color: #ffffff !important;
}

.unread-not {
    position: absolute;
    background: red;
    border-radius: 57px;
    font-size: 10px;
    color: white;
    width: 12px;
    position: absolute;
    top: 0;
    padding: .1rem .3rem;
    min-width: 1rem;
}

.b-bottom {
    border-bottom: solid 1px #7a7a7a7a;
}

.profile-item {
    margin-bottom: 5px;
}

.profile-item:hover {
    font-weight: bold;
    margin-bottom: 5px;
}

.main-wrapper {
    background-color: #fdf6ff !important;
}

.action-icon {
    padding: 5px;
    background-color: #9fb8ad33;
    border-radius: 4px;
    cursor: pointer;
}

.action-icon:hover {
    padding: 5px;
    background-color: #9fb8ad;
    border-radius: 4px;
    cursor: pointer;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
    color: #30404ea8 !important;
    background-color: #fdf6ff !important;
    font-size: 18px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #30404e !important;
    padding: 0;
    background-color: #fdf6ff !important;
    border-bottom: 2px solid #16c79a !important;
    border-radius: 0px !important;
}

.card-list-group .list-group-item {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.media img {
    height: 100px;
    width: 109px;
}

.post {
    position: absolute;
    right: 0 !important;
    padding: 4px 17px 4px 17px !important;
}

#highlight-div.active {
    background-color: rgba(255, 132, 83, 0.18);
}

.bid-btn {
    position: absolute;
    right: 20px;
    border-radius: 5px;
    background-color: #16c79a;
    color: #ffffff;
}

.apply-btn {
    position: absolute;
    right: 40px;
    border-radius: 5px;
    background-color: #0077C2;
    color: #ffffff;
    padding: 4px 15px 4px 15px;
}


/* .fe-heart {
    padding: 4px;
    background-color: rgba(251, 116, 62, 0.13);
    border-radius: 20px;
} */

.tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 132px;
    top: -9px;
    background-color: #fdd8c7;
    padding: 5px 0px;
    border-radius: 20px;
    z-index: 1;
}

.tooltiptext i:hover {
    cursor: pointer;
}

.tooltiptext .fe-heart {
    color: red;
    background-color: unset;
    ;
}

.tooltiptext .fe-star {
    color: #f7ff16;
}

.tooltiptext .fe-thumbs-up {
    color: #279dec;
}

.tooltiptext .fe-thumbs-down {
    color: #646464;
}

.utility:hover .tooltiptext {
    visibility: visible;
}

.fe-corner-up-right {
    padding: 4px;
    background-color: rgba(159, 184, 173, 0.25);
    border-radius: 20px;
}

.fe-edit-2 {
    padding: 4px;
    background-color: #eeff00;
    border-radius: 20px;
}

.comment-input-group:focus {
    box-shadow: 0 3px 10px 0 rgb(56 62 86 / 53%);
    border: solid 1px #383e56;
    background-color: #ffffff;
    border-radius: 20px;
}

.pseudo-search {
    display: inline;
    border: 2px solid #ccc;
    border-radius: 100px;
    padding: 6px 15px;
    transition: background-color 0.5 ease-in-out;
}

.pseudo-search input {
    border: 0;
    background-color: transparent;
    width: 100%;
}

.pseudo-search input:focus {
    outline: none;
}


/*{
    box-shadow: 0 3px 10px 0 rgba(56, 62, 86, 0.53);
  border: solid 1px #383e56;
  background-color: #ffffff;
}*/

.pseudo-search button,
.pseudo-search i {
    border: none;
    background-color: rgba(168, 239, 136, 0.31);
    cursor: pointer;
    padding: 4px;
    border-radius: 20px;
    font-size: 20px !important;
}

.pseudo-search select {
    border: none;
}

.notify .row:hover {
    background-color: #dfeade;
    box-shadow: 1px 3px 10px 0 rgba(56, 62, 86, 0.44);
}

.schedule .nav-pills .nav-link,
.nav-pills .show>.nav-link {
    color: #30404ea8;
    background-color: #fff !important;
    font-size: 12px;
}

.schedule .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #30404e;
    padding: 0;
    background-color: #fff !important;
    border-bottom: 2px solid #19456b !important;
    border-radius: 0px !important;
}

.create-post img {
    height: 28px;
}

.create-post:hover {
    border-radius: 28px;
    border: solid 1px #5056a8;
    cursor: pointer;
    background-color: rgba(80, 86, 168, 0.26);
}

.job:hover {
    border-radius: 28px;
    border: solid 1px #5056a8;
    background-color: rgba(80, 86, 168, 0.26);
    max-width: 106px;
}

.btn-oblong {
    border-radius: 18px;
    border: solid 0.5px #19456b;
    background-color: rgb(247 255 243);
    color: #747474;
    font-size: 11px;
}

.btn-oblong:hover {
    border-radius: 18px;
    border: solid 0.5px #16c79a;
    background-color: #16c79a;
    color: #ffffff;
    font-size: 11px;
}


/*::selection {
    background: yellow;
}

::-moz-selection {
    background: yellow;
}
*/


/*Project Page start*/

.project-content .nav-pills .nav-link,
.nav-pills .show>.nav-link {
    color: #707070 !important;
    background-color: #ffffff !important;
    border: solid 0px #9fb8ad !important;
    padding: 3px 20px 3px 20px;
    font-size: 15px;
    border-radius: 20px !important;
}

.project-content .nav-pills .nav-link:hover,
.nav-pills .show>.nav-link {
    color: #747474 !important;
    background-color: #7cacc13d !important;
    border: solid 1px #7cacc1 !important;
}

.project-content .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #ffffff !important;
    background-color: #7cacc1 !important;
    border: solid 0.5px #7cacc1 !important;
    padding: 3px 20px 3px 20px;
    font-size: 15px;
    border-radius: 20px !important;
}

.create-bid {
    position: absolute;
    top: -42px;
    right: 0px;
    padding: 8px 28px 8px 23px;
    object-fit: contain;
    border-radius: 18px;
    background-color: #16c79a;
    color: #ffffff;
}

.project-content table {
    text-align: inherit !important;
    /* border-spacing: 0 15px !important; */
    border-collapse: separate;
    padding: 5px;
}


/*.project-content table th {
    text-transform: inherit !important;
    !* font-weight: bold !important;*!
    color: #ffffff !important;
}*/

.btn-plus {
    padding: 14px 16px 14px 16px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ffffff;
    background-color: #16c79a;
    border-radius: 46px;
    z-index: 99;
    right: 126px;
    color: #ffff;
    position: fixed;
    bottom: 70px;
}

.project-content thead {
    border-radius: 10px;
    background-color: #dfeade !important;
    text-transform: inherit;
}

.project-content thead th {
    color: black !important;
    font-weight: bold;
    text-align: inherit !important;
    background-color: #fff !important;
}


/* tr {
    border-left: 2px solid #86dc55;
    border: 0.5px solid #9fb8ad;

}*/


/* .project-content tbody tr:hover>td:first-child {
    border-left: 3px solid #86dc55;
} */


/* .project-content tbody tr:hover {
    box-shadow: 0 3px 15px 0 #b4b4b4 !important;
} */

.chart-wrapper {
    background-color: #f8fffd;
}

.chart-wrapper:hover {
    box-shadow: 0px 0px 10px rgb(69 101 173 / 10%);
}

.create-pro {
    border: none;
    border-bottom: 1px solid #c8c5c5;
    border-radius: 0;
}

.project-inputs {
    border: none;
    border-bottom: 0px solid #c8c5c5;
    border-radius: 0;
}

.project-content hr {
    border-top: 2px solid rgb(140 143 147 / 62%) !important;
}

.drag-drop-card {
    border-radius: 5px;
    border: solid 1px #cecece;
    background-color: #ffffff;
}

.file-drop {
    border-radius: 5px;
    border: solid 2px #16c79a45;
    background-color: #f8fffd;
    border-style: dotted;
}

.btn-drag {
    border-radius: 5px;
    color: #ffffff;
    border: solid 1px #11698e;
    background-color: #11698e;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    z-index: 4;
    background: #7cacc1 !important;
    border: #7cacc1 !important;
}

.fc button,
.fc-event {
    background: #7cacc1 !important;
    border: #7cacc1 !important;
}

.pro-detail {
    /* box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16); */
    border: solid 0.5px #16c79a42;
    background-color: #f8fffd;
}

#pills-Articles table {
    /*border: solid 1px #707070;*/
    background-color: #fff;
}

.pro-detail input {
    background-color: #f8fffd !important;
}

#pills-Bidders .btn-massage,
.btn-view {
    padding: 3px 20px 3px 20px;
    border: 1px solid #16c79a;
    background-color: #ffffff;
}

#pills-Bidders .card {
    background-color: #d4e5fe;
}

#pills-Bidders .btn-view {
    background-color: #16c79a;
    color: #ffffff;
}

#pills-Bidders .btn-massage:hover,
.btn-view:hover {
    padding: 3px 20px 3px 20px;
    border: 1px solid #16c79a;
    background-color: #16c79a;
    color: #ffffff;
}

#frame {
    width: 100%;
    min-width: 360px;
    max-width: 1000px;
    height: 67vh;
    min-height: 300px;
    max-height: 720px;
    background: #E6EAEA;
}

@media screen and (max-width: 360px) {
    #frame {
        width: 100%;
        height: 100vh;
    }
}

#frame #sidepanel {
    float: left;
    min-width: 280px;
    max-width: 340px;
    width: 40%;
    height: 100%;
    border: solid 1px #d5d5d5;
    background: #ffffff;
    color: #000000;
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel {
        width: 58px;
        min-width: 58px;
    }
}

#frame #sidepanel #profile {
    width: 80%;
    margin: 25px auto;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile {
        width: 100%;
        margin: 0 auto;
        padding: 5px 0 0 0;
        background: #32465a;
    }
}

#frame #sidepanel #profile.expanded .wrap {
    height: 210px;
    line-height: initial;
}

#frame #sidepanel #profile.expanded .wrap p {
    margin-top: 20px;
}

#frame #sidepanel #profile.expanded .wrap i.expand-button {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

#frame #sidepanel #profile .wrap {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    -moz-transition: 0.3s height ease;
    -o-transition: 0.3s height ease;
    -webkit-transition: 0.3s height ease;
    transition: 0.3s height ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap {
        height: 55px;
    }
}

#frame #sidepanel #profile .wrap img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #e74c3c;
    height: auto;
    float: left;
    cursor: pointer;
    -moz-transition: 0.3s border ease;
    -o-transition: 0.3s border ease;
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap img {
        width: 40px;
        margin-left: 4px;
    }
}

#frame #sidepanel #profile .wrap img.online {
    border: 2px solid #2ecc71;
}

#frame #sidepanel #profile .wrap img.away {
    border: 2px solid #f1c40f;
}

#frame #sidepanel #profile .wrap img.busy {
    border: 2px solid #e74c3c;
}

#frame #sidepanel #profile .wrap img.offline {
    border: 2px solid #95a5a6;
}

#frame #sidepanel #profile .wrap p {
    float: left;
    margin-left: 15px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap p {
        display: none;
    }
}

#frame #sidepanel #profile .wrap i.expand-button {
    float: right;
    margin-top: 23px;
    font-size: 0.8em;
    cursor: pointer;
    color: #435f7a;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap i.expand-button {
        display: none;
    }
}

#frame #sidepanel #profile .wrap #status-options {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    margin: 70px 0 0 0;
    border-radius: 6px;
    z-index: 99;
    line-height: initial;
    background: #435f7a;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options {
        width: 58px;
        margin-top: 57px;
    }
}

#frame #sidepanel #profile .wrap #status-options.active {
    opacity: 1;
    visibility: visible;
    margin: 75px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options.active {
        margin-top: 62px;
    }
}

#frame #sidepanel #profile .wrap #status-options:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #435f7a;
    margin: -8px 0 0 24px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options:before {
        margin-left: 23px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul {
    overflow: hidden;
    border-radius: 6px;
}

#frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 30px 18px;
    display: block;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li {
        padding: 15px 0 35px 22px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li:hover {
    background: #496886;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
        width: 14px;
        height: 14px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px;
    background: transparent;
    border-radius: 50%;
    z-index: 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
        height: 18px;
        width: 18px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li p {
    padding-left: 12px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li p {
        display: none;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
    background: #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
    border: 1px solid #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
    background: #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
    border: 1px solid #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
    background: #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
    border: 1px solid #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
    background: #95a5a6;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
    border: 1px solid #95a5a6;
}

#frame #sidepanel #profile .wrap #expanded {
    padding: 100px 0 0 0;
    display: block;
    line-height: initial !important;
}

#frame #sidepanel #profile .wrap #expanded label {
    float: left;
    clear: both;
    margin: 0 8px 5px 0;
    padding: 5px 0;
}

#frame #sidepanel #profile .wrap #expanded input {
    border: none;
    margin-bottom: 6px;
    background: #32465a;
    border-radius: 3px;
    color: #f5f5f5;
    padding: 7px;
    width: calc(100% - 43px);
}

#frame #sidepanel #profile .wrap #expanded input:focus {
    outline: none;
    background: #ffffff;
    color: #000;
}

#frame #sidepanel #search {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    font-weight: 300;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #search {
        display: none;
    }
}

#frame #sidepanel #search label {
    position: absolute;
    margin: 10px 0 0 20px;
}

#frame #sidepanel #search input {
    padding: 10px 0 10px 46px;
    width: calc(100% - 25px);
    border: none;
    background: #ffffff !important;
    color: #000000 !important;
}

#frame #sidepanel #search input:focus {
    outline: none;
    background: #435f7a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
    color: #000;
}

#frame #sidepanel #search input::-moz-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #contacts {
    height: calc(100% - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts {
        height: calc(100% - 149px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
    #frame #sidepanel #contacts::-webkit-scrollbar {
        display: none;
    }
}

#frame #sidepanel #contacts.expanded {
    height: calc(100% - 334px);
}

#frame #sidepanel #contacts::-webkit-scrollbar {
    width: 8px;
    /*  background: #9fb8ad;*/
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    /*    background-color: #9fb8ad;*/
}

#frame #sidepanel #contacts ul li.contact {
    position: relative;
    padding: 10px 0 15px 0;
    font-size: 0.9em;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact {
        padding: 6px 0 46px 8px;
    }
}

#frame #sidepanel #contacts ul li.contact:hover {
    background: rgba(124, 172, 193, 0.43);
    color: #000;
}

#frame #sidepanel #contacts ul li.contact.active {
    background: rgba(124, 172, 193, 0.43);
    color: #000;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
    border: 2px solid #32465a !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
    width: 88%;
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap {
        width: 100%;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #95a5a6;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
    background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
    background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
    background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap img {
        margin-right: 0px;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta {
    padding: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap .meta {
        display: none;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
    font-weight: 600;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
    margin: 5px 0 0 0;
    padding: 0 0 1px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
}

#frame #sidepanel #bottom-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
}

#frame #sidepanel #bottom-bar button {
    float: left;
    border: 1px solid #cecece;
    width: 50%;
    padding: 10px 0;
    background: #ffffff;
    color: #000000;
    cursor: pointer;
    font-size: 0.85em;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button {
        float: none;
        width: 100%;
        padding: 15px 0;
    }
}

#frame #sidepanel #bottom-bar button:focus {
    outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: 1px solid #2c3e50;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button:nth-child(1) {
        border-right: none;
        border-bottom: 1px solid #2c3e50;
    }
}

#frame #sidepanel #bottom-bar button:hover {
    background: #9fb8ad;
}

#frame #sidepanel #bottom-bar button i {
    margin-right: 3px;
    font-size: 1em;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button i {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button span {
        display: none;
    }
}

#frame .content {
    float: right;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame .content {
        width: calc(100% - 58px);
        min-width: 300px !important;
    }
}

@media screen and (min-width: 900px) {
    #frame .content {
        width: calc(100% - 340px);
    }
}

#frame .content .contact-profile {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #000000;
    box-shadow: 6px 6px 6px 0 rgb(0 0 0 / 16%);
    background-color: rgba(124, 172, 193, 0.43);
}

#frame .content .contact-profile img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin: 9px 12px 0 9px;
}

#frame .content .contact-profile p {
    float: left;
}

#frame .content .contact-profile .social-media {
    float: right;
}

#frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
    color: #435f7a;
}

#frame .content .messages {
    height: auto;
    width: 100%;
    min-height: calc(100% - 93px);
    max-height: calc(100% - 93px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 735px) {
    #frame .content .messages {
        max-height: calc(100% - 105px);
    }
}

#frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
}

#frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
}

#frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
}

#frame .content .messages ul li.sent p {
    color: #000000;
    background-color: rgba(124, 172, 193, 0.45);
}

#frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
}

#frame .content .messages ul li.replies p {
    background-color: rgba(22, 199, 154, 0.28);
    float: right;
}

#frame .content .messages ul li img {
    width: 22px;
    border-radius: 50%;
    float: left;
}

#frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
}

@media screen and (min-width: 735px) {
    #frame .content .messages ul li p {
        max-width: 300px;
    }
}

#frame .content .message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
    background: #fff;
}

#frame .content .message-input .wrap {
    position: relative;
}

#frame .content .message-input .wrap input {
    float: left;
    border: none;
    width: calc(100% - 90px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: #32465a;
    border: 1px solid #80808054;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap input {
        padding: 15px 32px 16px 8px;
    }
}

#frame .content .message-input .wrap input:focus {
    outline: none;
}

#frame .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #435f7a;
    opacity: .5;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap .attachment {
        margin-top: 17px;
        right: 65px;
    }
}

#frame .content .message-input .wrap .attachment:hover {
    opacity: 1;
}

#frame .content .message-input .wrap button {
    float: right;
    border: none;
    width: 50px;
    padding: 12px 0;
    cursor: pointer;
    background: rgba(124, 172, 193, 0.43);
    color: #000;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap button {
        padding: 16px 0;
    }
}

#frame .content .message-input .wrap button:hover {
    background: #9fb8ad;
}

#frame .content .message-input .wrap button:focus {
    outline: none;
}

#InvitionModal .nav-tabs .nav-link.active {
    color: #000;
    background: #ffffff00;
    border-bottom: 3px solid #16c79a;
}

#InvitionModal .nav-tabs .nav-link:hover {
    color: #000;
    background: #ffffff00;
    border-bottom: 3px solid #16c79a;
}

#InvitionModal .btn-default {
    padding: 3px 20px 3px 20px;
    background-color: #16c79a;
    color: #fff;
    border-radius: 20px;
}

#InvitionModal .btn-Invitation {
    padding: 3px 20px 3px 20px;
    background-color: #16c79a;
    color: #fff;
    border-radius: 20px;
}

#InvitionModal .card {
    border: solid 1px #cecece;
}

#Attendees .btn-default {
    padding: 3px 20px 3px 20px;
    background-color: #16c79a;
    color: #fff;
    border-radius: 20px;
}

#Attendees .card {
    border: solid 1px #cecece;
}

#Member .btn-default {
    padding: 3px 20px 3px 20px;
    background-color: #16c79a;
    color: #fff;
    border-radius: 20px;
}

#Member .card {
    border: solid 1px #cecece;
}

#pills-assistant ul li {
    padding: 10px;
    color: #000;
}

#pills-assistant ul li.active {
    padding: 10px;
    background: #7cacc1;
    color: #fff;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #16c79a !important;
}

.btn-massage {
    margin-left: 5px;
    padding: 5px 10px 5px 10px;
    border: solid 1px #cecece;
    background-color: #0077C2;
    color: #fff;
}

.btn-temp {
    padding: 9px 30px 9px 30px;
    border: solid 1px #707070;
    background-color: #ffffff;
    color: #000;
    border-radius: 20px;
}

.btn-csv {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #0077C2;
    color: #fff;
    border-radius: 20px;
}

.btn-add-item {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #0077C2;
    color: #fff;
}

.btn-view-item {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #11698e;
    color: #fff;
}

.btn-edit-item {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #7cacc1;
    color: #fff;
}

.btn-float-tender {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #57bce2;
    color: #fff;
}

.btn-float-tender:hover {
    background: #f29a32;
}

.btn-prev {
    padding: 9px 30px 9px 30px;
    border: solid 1px #0077C2;
    background-color: #ffffff;
    color: #11698e;
}

.btn-send {
    padding: 9px 40px 9px 40px;
    border-radius: 5px;
    background-color: #0077C2;
    color: #fff;
}


/*#sheduleMeeting .modal-content {
    background-color: #ffffff !important;
}*/

.modal-content {
    background-image: linear-gradient(115deg, #dafcf3 6%, #bae4f6 95%);
}

#pills-Team .btn-default,
#pills-Calender .btn-default {
    background: #fff;
    border: solid 1px #cecece;
}

tbody .btn-default {
    border: 1px solid #cecece;
    color: #555454;
}

tbody .btn-default:hover {
    background-color: #7cacc1;
    color: #ffffff;
}


/*Floating Button*/

.fab {
    background-color: #ffffff;
    height: 45px;
    width: 45px;
    border-radius: 22px;
    transition: height 300ms;
    transition-timing-function: ease;
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab:hover {
    height: 120px;
    width: 140px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}

.fab:hover .mainop {
    transform: rotate(180deg);
}

.float-btn-list {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.float-btn-list li {
    display: flex;
    color: #19456b;
    padding: 0px 0px 5px 0px;
}

.float-btn-list li a {
    color: #19456b;
}

.float-btn-list p {
    height: 8px;
    margin: 8px;
    width: 8px;
    border-radius: 20px;
    background-color: #16c79a;
}

.mainop {
    margin: auto;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 300ms;
    background-color: #0077C2;
    border-radius: 32px;
    z-index: 6;
}

.mainopShadow {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    position: absolute;
    right: 50px;
    bottom: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mainop i {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 11px;
    right: 11px;
}

.minifab {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    z-index: 5;
    float: left;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    background-color: blue;
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.minifab:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.minifabIcon {
    height: 24px;
    width: 24px;
    margin-top: 12px;
}

.op1 {
    background-color: #2f82fc;
}

.op2 {
    background-color: #0f9d58;
}

.op3 {
    background-color: #fb0;
}

.op4 {
    background-color: #dd5145;
}

.op5 {
    background-color: #673ab7;
}

#FilterModal .btn-default,
#FilterInvoice .btn-default,
#FilterTeams .btn-default,
#FilterBids .btn-default,
#FeedModal .btn-default,
#VideoModal .btn-default,
#DocModal .btn-default,
#JobModal .btn-default {
    padding: 5px 30px 5px 30px;
    background: #16c79a;
    color: #fff;
}

#FilterModal .modal-content,
#FilterInvoice .modal-content,
#FilterTeams .modal-content,
#FilterBids .modal-content,
#FilterPDF .modal-content,
#ScoreModal .modal-content,
#FeedModal .modal-content,
#DocModal .modal-content,
#VideoModal .modal-content,
#JobModal .modal-content,
#CreateJobModal .modal-content {
    background-image: linear-gradient(115deg, #ffffff 6%, #ffffff 95%) !important;
}

.btn-secondary {
    color: #575757 !important;
    padding: 5px 30px 5px 30px !important;
    background-color: #ffffff !important;
    border-color: #d7d7d7 !important;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%) !important;
}

textarea {
    resize: none;
}

.progress-bar {
    background-color: #16c79a !important;
}


/*End*/


/*End*/

@media only screen and (max-width: 768px) {
    .btn-oblong {
        font-size: 8px;
    }
}

.app-content .side-app {
    padding: 47px 30px 0 0px;
}

.card,
.notify .row {
    border: solid 1px #cecece;
}

.height {
    height: 333px;
    overflow: auto;
}

.social-links a {
    font-size: 10px !important;
}

.chart {
    position: relative;
    padding-top: 0%;
}

.metric {
    padding: 10%;
}

.metric svg {
    max-width: 100%;
}

.metric path {
    stroke-width: 75;
    stroke: #ecf0f1;
    fill: none;
}

.metric.participation path.data-arc {
    stroke: #f10202;
}

.metric.participation text {
    fill: #f10202;
}

.metric.completion path.data-arc {
    stroke: #27ae60;
}

.metric.completion text {
    fill: #27ae60;
}

.metric.blocked path.data-arc {
    stroke: #e74c3c;
}

.metric.blocked text {
    fill: #e74c3c;
}

.fa-star,
.fa-star-o {
    color: #fabe2c;
}